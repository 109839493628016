<template>
	<div class="Calendar">
		<w-navTab titleText="直播日历"></w-navTab>
		<div class="header">
			<W-Calendar v-on:choseDay="clickDay" v-on:changeMonth="changeDate" :sundayStart="true"
				:markDateMore="timeList"></W-Calendar>
		</div>
		<div class="container-body">
			<div class="body-list">
				<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty"
					@onLoad="onLoad">
					<div :class=" ['list-item', { 'list-item-active': item.state == 2 }]" v-for="(item,index) in List"
						@click="$router.push({ name: 'TelecastDetail', params:{id: item.live_id}})">
						<div class="item-picture">
							<img :src="item.thumb" alt="">
							<div class="item-picture-box">{{item.type_name}}</div>
						</div>
						<div class="item-right">
							<div class="item-right-title">
								{{item.title}}
							</div>
							<div class="item-right-box">
								<div class="item-right-box-icon" v-if="item.state == 1 || item.state == 3">
									<img src="@/assets/img/icon83.png" alt="">时间：
								</div>
								<div class="item-right-box-icon" v-else>
									<img src="@/assets/img/icon84.png" alt="">
								</div>
								<div class="item-right-box-text" v-if="item.state == 1 || item.state == 3">
									{{item.play_date}}
								</div>
								<div class="item-right-box-text item-right-box-text-active" v-if="item.state == 2">
									正在直播
								</div>
							</div>
							<div class="item-right-botton" v-if="item.state == 1 && item.is_notice == 2"
								@click.stop="onLiveNotice(item.live_id,index)">开播提醒</div>
							<div class="item-right-botton" v-if="item.state == 2">立即观看</div>
							<div class="item-right-botton" v-if="item.state == 3">直播回放</div>
							<div class="botton-active" v-if="item.state == 1 && item.is_notice == 1">已设提醒</div>

						</div>
						<div class="list-item-watermark" v-if="item.state == 2">
							<img src="@/assets/img/icon92.png" alt="">
						</div>
					</div>
				</VantList>
			</div>
		</div>
	</div>
</template>
<script>
	import Moment from '@/vendor/moment';
	import Live from '@/api/live';
	import Vant from '@/vendor/vant';
	import VantList from '@/components/VantList';
	import WCalendar from 'vue-calendar-component';
	export default {
		name: 'Calendar',
		data() {
			return {
				List: [],
				timeList: [],
				month: "",
				loading: true,
				finished: false,
				isEmpty: false,
			}
		},
		created() {
			this.month = Moment(new Date() / 1000).valueOf();
			this.onCalendar(this.month)
			this.onLoad(this.month)
		},
		methods: {
			// 设置提醒
			onLiveNotice(id, index) {
				Live.liveNotice({
						live_id: id
					})
					.then(result => {
						Vant.Dialog.alert({
							title: '订阅通知',
							message: '订阅成功',
							theme: 'round-button',
						}).then(() => {
							this.List[index].is_notice = 1
						});
					}, error => {
						Vant.Toast(error.msg);
					})
			},
			onCalendar(month) {
				Live.liveCalendar({
						month: month
					})
					.then(result => {
						result.data.forEach(item => {
							let time = Moment(new Date()).format('YYYY-MM-DD')
							let itemTime = Moment(item * 1000).format('YYYY-MM-DD')
							if (time == itemTime) {
								let list = {
									date: itemTime,
									className: "mark2"
								}
								this.timeList.push(list)
							} else {
								let list2 = {
									date: itemTime,
									className: "mark1"
								}
								this.timeList.push(list2)
							}
						})
					}, error => {
						Vant.Toast(error.msg);
					})
			},
			onLoad(month) {
				Live.liveDayLives({
						day: month
					})
					.then(result => {
						this.loading = true
						this.List = this.List.concat(result.data);
						this.loading = false
						if (this.List.length <= 0) {
							this.isEmpty = true;
						}

						if (this.List.length >= result.data.length) {
							this.finished = true;
						}
					}, error => {
						Vant.Toast(error.msg);
					})
			},
			clickDay(data) {
				let time = Moment(data).valueOf() / 1000
				this.isEmpty = false
				this.List = []
				this.onCalendar(time)
				this.onLoad(time)
			},
			changeDate(data) {
				let time = Moment(data).valueOf() / 1000
				this.isEmpty = false
				this.List = []
				this.onCalendar(time)
				this.onLoad(time)
			}
		},
		components: {
			WCalendar,
			VantList
		}
	}
</script>

<style scoped lang="less">
	.Calendar {
		.header {
			/deep/.wh_container {
				.wh_content_li {
					color: #333;
				}

				.wh_jiantou1 {
					border-top: 2px solid #000;
					border-left: 2px solid #000;
				}

				.wh_jiantou2 {
					border-top: 2px solid #000;
					border-right: 2px solid #000;
				}

				.wh_content_all {
					background: #FFF;
				}

				.wh_content_item {
					color: #333;

					.wh_isMark {
						border-radius: 8px;
						background: rgba(47, 128, 237, 0.2);
					}

					.wh_chose_day {
						width: 36px;
						height: 36px;
						color: #128AF6;
						border-radius: 8px;
						background: rgba(47, 128, 237, 0.2);
						border: 2px solid #3377FF;
					}

					.wh_isToday {
						color: #fff;
						border-radius: 8px;
						background-color: #2F80ED;
					}

					.mark1 {
						border-radius: 8px;
						background: rgba(47, 128, 237, 0.2);
					}

					.mark2 {
						color: #fff !important;
						border-radius: 8px;
						background-color: #2F80ED !important;
					}
				}
			}
		}

		.container-body {
			padding: 0 8px;

			.body-list {
				.list-item-active {
					background: linear-gradient(180deg, #EFF5FF 0%, #E0F0FF 100%) !important;
					border-radius: 8px;
				}

				.list-item {
					display: flex;
					align-items: center;
					background: #FFF;
					border-radius: 8px;
					padding: 8px;
					box-sizing: border-box;
					position: relative;
					margin-top: 8px;

					.list-item-watermark {
						width: 123px;
						height: 84px;
						position: absolute;
						right: 0;
						bottom: 0;
						z-index: 0;
						
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.item-picture {
						width: 130px;
						height: 130px;
						position: relative;
						border-radius: 2px;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						.item-picture-box {
							width: 75px;
							position: absolute;
							top: 0;
							left: 0;
							background: url('~@/assets/img/icon91.png');
							background-size: cover;
							font-size: 13px;
							line-height: 19px;
							color: #FFF;
							padding-left: 3px;
							padding-top: 3px;
							box-sizing: border-box;
						}
					}

					.item-right {
						padding-left: 7px;

						.item-right-title {
							font-weight: bold;
							font-size: 17px;
							line-height: 20px;
							color: #44484E;
							min-height: 40px;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}

						.item-right-box {
							display: flex;
							margin-top: 5px;

							.item-right-box-icon {
								font-size: 13px;
								line-height: 19px;
								color: #777B81;
								display: flex;
								
								img {
									width: 16px;
									height: 16px;
									margin-right: 2px;
									object-fit: cover;
								}
							}

							.item-right-box-text {
								width: 110px;
								font-size: 13px;
								line-height: 19px;
								color: #777B81;
							}

							.item-right-box-text-active {
								color: #FF4E3B;
							}
						}

						.item-right-botton {
							min-width: 110px;
							max-width: 110px;
							font-weight: bold;
							font-size: 15px;
							line-height: 22px;
							color: #FFF;
							text-align: center;
							padding: 3px 21px;
							background: #128AF6;
							box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
							border-radius: 100px;
							margin-top: 19px;
							box-sizing: border-box;
							position: relative;
							z-index: 1;
						}

						.botton-active {
							border: 1px solid #128AF6;
							min-width: 108px;
							max-width: 108px;
							text-align: center;
							font-weight: bold;
							font-size: 15px;
							line-height: 22px;
							color: #128AF6;
							padding: 3px 21px;
							box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
							border-radius: 100px;
							margin-top: 19px;
							box-sizing: border-box;
							position: relative;
							z-index: 1;
						}
					}
				}
			}
		}

	}
</style>
